import { Button } from "baseui/button"
import { Optgroups, Select, Value } from "baseui/select"

type FilterItemProps = {
  label: string
  labelPosition?: "top" | "left"
  options?:
    | Optgroups
    | {
        id: string
        label: string
      }[]
  value: Value
  setValue(value: Value): void
  canSelectAll?: boolean
  radio?: boolean
  clearable?: boolean
}

export function FilterItem({
  label,
  options,
  value,
  setValue,
  canSelectAll,
  radio,
  labelPosition,
  clearable = true,
}: FilterItemProps) {
  return (
    <div
      className={`flex ${
        labelPosition === "left" ? "flex-row gap-x-2" : "my-2 flex-col gap-y-2"
      }`}
    >
      <div className="flex items-center justify-between text-sm font-medium">
        <span>{label}</span>
        {canSelectAll && (
          <div>
            <Button
              size="mini"
              onClick={() => {
                if (value.length) {
                  setValue([])
                } else {
                  if (!options) return
                  if (Array.isArray(options)) {
                    setValue(options)
                  } else {
                    setValue(
                      Object.values(options as Optgroups).flatMap((x) => x)
                    )
                  }
                }
              }}
            >
              {value.length ? "선택 해제" : "전체 선택"}
            </Button>
          </div>
        )}
      </div>
      <div className="flex-grow">
        <Select
          multi={!radio}
          closeOnSelect={!!radio}
          options={options}
          value={value}
          onSelectResetsInput={false}
          onChange={({ value }) => {
            setValue(value)
          }}
          clearable={clearable}
          backspaceRemoves={clearable}
        />
      </div>
    </div>
  )
}
