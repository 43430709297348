import { useUserInfo } from "@today/auth"
import { Organization, OrgId, OrgIds } from "@today/api/tracker"
import { ComponentType, ReactNode } from "react"
import { FaLock } from "react-icons/fa"
import { ROLE } from "../utils"

function Unauthorized() {
  return (
    <div className="mt-12 flex items-center justify-center gap-x-2 text-xl font-semibold">
      <FaLock />이 페이지를 볼 수 있는 권한이 없습니다.
    </div>
  )
}

type AuthGuardProps = {
  allowedRoles: string[]
  children: ReactNode
}

function AuthGuardPage({ allowedRoles, children }: AuthGuardProps) {
  const { isAuthenticated, clientRoles } = useUserInfo()
  if (!isAuthenticated) {
    return <></>
  }
  if (!allowedRoles.some((allowedRole) => clientRoles.includes(allowedRole))) {
    return <Unauthorized />
  }
  return <>{children}</>
}

export function withAuthGuard(
  Component: ComponentType,
  allowedRoles: string[]
) {
  const wrapped = () => (
    <AuthGuardPage allowedRoles={allowedRoles}>
      <Component />
    </AuthGuardPage>
  )
  return wrapped
}

export function AuthGuardSection({ allowedRoles, children }: AuthGuardProps) {
  const { isAuthenticated, clientRoles } = useUserInfo()
  if (
    !isAuthenticated ||
    !allowedRoles.some((allowedRole) => clientRoles.includes(allowedRole))
  ) {
    return <></>
  }
  return <>{children}</>
}

type OrgAuthGuardProps = {
  orgId: Organization["id"]
  children: ReactNode
}

export function OrgAuthGuard({ orgId, children }: OrgAuthGuardProps) {
  const { clientRoles, outsourcingOrganizationId } = useUserInfo()

  if (
    clientRoles.includes(ROLE.admin) || // keycloak vtov (본사 직원) 그룹이 보유 중인 권한
    clientRoles.includes(ROLE.todayMaster) || // keycloak today > master 그룹이 보유 중인 권한
    (clientRoles.includes(ROLE.outsourcingMaster) && // 외주 협력사일 경우, 해당 외주 협력사의 페이지만 권한을 부여한다.
      orgId == outsourcingOrganizationId)
  )
    return <>{children}</>

  return <Unauthorized />
}
